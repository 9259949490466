<template>
  <div v-if="itemsHindi.length > 0 || itemsEnglish.length > 0">
    <v-tabs grow v-model="tab">
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab> English </v-tab>
      <v-tab> हिन्दी </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="pa-2">
      <v-tab-item>
        <v-row dense class="justify-center">
          <v-col cols="6" md="3" v-for="item in itemsEnglish" :key="item.id">
            <v-card
              outlined
              @click="handleClick(item)"
              height="100"
              class="justify-center align-center d-flex flex-column"
            >
              <v-card-title class="body-2">
                {{ item.title }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row dense class="justify-center">
          <v-col cols="6" md="3" v-for="item in itemsHindi" :key="item.id">
            <v-card
              outlined
              @click="handleClick(item)"
              height="100"
              class="justify-center align-center d-flex flex-column"
            >
              <v-card-title class="body-2">
                {{ item.title }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <v-col cols="12" v-else>
    <v-card-title class="justify-center">
      <v-progress-linear indeterminate class="mb-6"></v-progress-linear>
      {{ $lang("PleaseWait") }}
    </v-card-title>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import bus from "@/bus";

export default {
  components: {},
  name: "learn-subjects",
  data() {
    return {
      tab: 0,
      itemsHindi: [],
      itemsEnglish: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      getCurrLocale: "getCurrLocale",
    }),
  },
  watch: {
    getCurrLocale(val) {
      this.tab = this.getCurrLocale == "hi" ? 1 : 0;
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push({
        name: "learn-categories",
        params: { subject: item.title },
      });
    },

    fetchItems() {
      axios.get("learn/lang/en").then((res) => {
        this.itemsEnglish = res.data.data;
      });
      axios.get("learn/lang/hi").then((res) => {
        this.itemsHindi = res.data.data;
      });
      this.tab = this.getCurrLocale == "hi" ? 1 : 0;
    },
  },
  mounted() {
    document.title = ` Learn | vikarn.com`;
    this.fetchItems();
  },
};
</script>

